import { TFunction } from 'next-i18next'
import { z } from 'zod'
import { removeHTMLTags } from '~/core/utilities/common'

export const schemaCustomField = (
  t: TFunction,
  requiredKeyFields?: Array<number>
) => {
  return z.object({
    customFields: z
      .record(
        z.string(),
        z
          .object({
            id: z.string().or(z.number()),
            type: z.string(),
            value: z
              .any()
              .optional()
              .or(z.boolean())
              .or(
                z.object({
                  year: z.number().nullish().optional(),
                  month: z.number().nullish().optional(),
                  date: z.number().nullish().optional()
                })
              )
              .or(z.array(z.string()))
              .or(
                z.array(
                  z.object({
                    value: z.string(),
                    supportingObj: z.object({
                      description: z.string().optional(),
                      name: z.string()
                    })
                  })
                )
              )
              .default(''),
            objectKind: z.string().optional(),
            label: z.string().optional(),
            index: z.number().optional(),
            roleIds: z.array(z.number()).nullish().optional(),
            selectOptions: z
              .array(
                z.object({
                  value: z.string(),
                  supportingObj: z.object({
                    description: z.string().optional(),
                    name: z.string()
                  })
                })
              )
              .nullish()
              .optional()
          })
          .refine(
            (customField) => {
              let customFieldValid = true
              if (
                customField.type === 'text' &&
                typeof customField.value === 'string'
              ) {
                customFieldValid =
                  (customField.value || '').trim().length <= 100
              }

              return !customField || customFieldValid
            },
            {
              message: `${t('form:field_max_number_required', { number: 100 })}`
            }
          )
          .refine(
            (customField) => {
              let customFieldValid = true
              if (
                customField.type === 'paragraph' &&
                typeof customField.value === 'string'
              ) {
                customFieldValid =
                  removeHTMLTags((customField.value || '').trim()).length <=
                  10000
              }

              return !customField || customFieldValid
            },
            {
              message: `${t('form:field_max_number_required', {
                number: 10000
              })}`
            }
          )
          .refine(
            (customField) => {
              let customFieldValid = true
              if (customField.type === 'number') {
                customFieldValid =
                  Number(customField.value) >= 0 || !customField.value
              }

              return !customField || customFieldValid
            },
            {
              message: `${t('form:numberMustBeGreater', { number: 0 })}`
            }
          )
          .refine(
            (customField) => {
              let customFieldValid = true
              if (customField.type === 'number') {
                customFieldValid =
                  Number(customField.value) <= 2100000000 ||
                  (String(customField.value) !== '0' && !customField.value)
              }

              return !customField || customFieldValid
            },
            {
              message: `${t('form:additional_field_number_max_value')}`
            }
          )
          .refine(
            (customField) => {
              //To-do: handle multi select value
              const isCheckedRequiredField = requiredKeyFields?.includes(
                Number(customField.id)
              )
              let isPresentValue = true
              if (typeof customField.value === 'object') {
                isPresentValue = !!(customField.value as { date?: number })
                  ?.date
              } else if (typeof customField.value === 'string') {
                isPresentValue = customField.value?.length > 0
              }

              if (isCheckedRequiredField && !isPresentValue) {
                return false
              }

              return true
            },
            {
              message: `${t('form:requiredField')}`
            }
          )
      )
      .default({})
  })
}
